import cryptojs from 'crypto-js';
import {
    Http_Domain_Localhost,
    Http_Domain_Test,
    Http_Domain_Production,
    Aes_Key,
    Aes_IvParam
} from './HttpConfig';

/**
 * 把数据发送给客户端
 * @param {Object} _obj 所有的参数
 * @param {Object} _obj 需要发送的数据
 */
export function encodeToSever(_obj) {
    var str = "";
    if (_obj) {
        try {
            // 将结构体转为字符串
            str = JSON.stringify(_obj);
            //加密
            var encryptedStr = cryptojs.AES.encrypt(str, cryptojs.enc.Utf8.parse(Aes_Key), {
                iv: cryptojs.enc.Utf8.parse(Aes_IvParam),
                mode: cryptojs.mode.CBC,
                padding: cryptojs.pad.Pkcs7
            });
            // 格式化字符串
            str = encryptedStr.toString();
            // 进行uri编码
            str = encodeURIComponent(str);
        } catch (_error) {
            console.log(":json加密失败", _error);
            // 返回给客户端
            str = "";
        }
    }
    return str;
}
/**
 * 解析成明文
 */
export function decodeForClient(oldStr) {
    var paramsObj = null;
    if (oldStr && oldStr.length > 0) {
        try {
            // 进行unescape编码
            oldStr = decodeURIComponent(oldStr);
            // 将数据进行解密
            var decryptedStr = cryptojs.AES.decrypt(oldStr, cryptojs.enc.Utf8.parse(Aes_Key), {
                iv: cryptojs.enc.Utf8.parse(Aes_IvParam),
                mode: cryptojs.mode.CBC,
                padding: cryptojs.pad.Pkcs7
            });
            // 格式化字符串
            oldStr = decryptedStr.toString(cryptojs.enc.Utf8);
            // 将解码后的结果转为Object
            paramsObj = JSON.parse(oldStr);
        } catch (error) {
            console.log(":url解析异常", error);
            oldStr = "";
        }
    }
    return paramsObj;
}
/**
  * 执行最终的请求
  * @param {string} _actionStr 访问路径
  * @param {Object} _obj 访问参数
  * @param {function} _tcb 回调方法
  */
function request(_actionStr, _obj, _tcb, _domain) {
    // 使用window.location对象获取当前页面的URL
    var url = window.location.href;
    // 使用正则表达式解析出域名
    var curDomain = url.match(/:\/\/(.[^/]+)/)[1];
    if (!_domain) {
        if (process.env.REACT_APP_MODE === 'production') { // 生产环境
            _domain = "https://" + curDomain// Http_Domain_Production;
        } else if (process.env.REACT_APP_MODE === 'test') { // 测试环境
            _domain = Http_Domain_Test;
        } else if (process.env.REACT_APP_MODE === 'localhost') { // 本地（公司）
            _domain = Http_Domain_Localhost;
        } else {
            _domain = Http_Domain_Localhost;
        }
    }
    var xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) { // 接口链接完成
            if (xhr.status >= 200 && xhr.status < 400) {
                if (process.env.REACT_APP_MODE !== 'production') { // 非生产环境（新加坡）
                    console.log('返回结果：', decodeForClient(xhr.responseText));
                }
                // 返回正确的内容
                if (_tcb) {
                    _tcb(decodeForClient(xhr.responseText));
                }
            } else { // 异常错误
                if (_tcb) {
                    _tcb({});
                }
            }
        }
    };
    // 判空处理
    if (!_obj) {
        _obj = {};
    }
    if (process.env.REACT_APP_MODE !== 'production') { // 非生产环境（新加坡）
        console.log('请求参数：', _obj);
    }
    // 加密内容
    var content = encodeToSever(_obj);
    // 构建访问地址
    var url = _domain + _actionStr;
    // 指定为post类型
    xhr.open("POST", url, true);
    // 设置内容格式
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;");
    // 构建满足后端解析的参数来提交
    xhr.send("ep=" + content);
}
// ------------------------------------ ↓ 接口 ↓ ------------------------------------
/**
 * 查询游戏列表接口
 * @param {*} _ct ：100是全部，101是最热，102是最新，103是推荐
 * @param {*} _pg ：第几页
 * @param {*} _cb ：回调
 */
export function doQueryList(_ct, _pg, _cb) {
    // 请求参数
    var tmpObj = {};
    // 分类类型 100是全部，101是最热，102是最新，103是推荐
    tmpObj.ct = _ct;
    // 第几页
    tmpObj.pg = _pg;
    // 执行请求
    request("/hfbox/ctlt", tmpObj, function (_resultObj) {
        if (_cb) {
            _cb(_resultObj);
        }
    });
}
/**
 * 查询游戏数据
 * @param {*} _val ：查询关键字
 * @param {*} _cb ：回调
 */
export function doSearchGame(_val, _cb) {
    // 请求参数
    var tmpObj = {};
    // 游戏id
    tmpObj.gid = _val;
    // 执行请求
    request("/hfbox/gdtl", tmpObj, function (_resultObj) {
        if (_cb) {
            _cb(_resultObj);
        }
    });
}
// ------------------------------------ ↑ 接口 ↑ ------------------------------------

