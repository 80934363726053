import React from 'react';
import { Toast, List, PullToRefresh, InfiniteScroll, DotLoading } from 'antd-mobile';
import { Redirect, withRouter } from "react-router-dom";
import { LoadingView, EmptyView, GameIcon, Adsbygoogle, PageBottom, MyMenu } from '@component';
import { IMAGE } from "@/assets";
import { GlobalCtg } from "@config/config";
import { doQueryList } from '@util/HttpUtil';
import { getErrorCodeMsg, getLastGameList, initAdScript } from '@util/GameUtil';
import './styles.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, // 是否加载中
            isEmpty: false, // 是否为空 
            isMenu: false, // 是否展示菜单
            showWhat: 1, // 展示什么
            listNa: 'All Games', // 列表名称
            showList: [], // 展示列表
            hasMore: true, // 是否还有更多数据
            fromWhere: GlobalCtg.listType.all, // 来自哪里
        }
        // 组件是否挂载了
        this.mounted = false;
        // 游戏类型
        this.gameType = GlobalCtg.listType.all;
        // 是否加载数据中
        this.isLoadingData = false;
        // 列表加载的页数
        this.loadPage = 1;
        // 游戏列表
        this.gameList = [];
    }
    // 从服务器请求数据
    requestFromServer() {
        // 获取列表类型
        const { gty } = this.props.match.params;
        // 记录类型
        this.gameType = Number(gty);
        // 列表名称
        let na = 'All Games';
        // 根据类型设置名称
        if (this.gameType === GlobalCtg.listType.hots) {
            na = 'Hot Games';
        } else if (this.gameType === GlobalCtg.listType.news) {
            na = 'New Games';
        } else if (this.gameType === GlobalCtg.listType.last) {
            na = 'Played games';
        } else if (this.gameType === GlobalCtg.listType.push) {
            na = 'Recommend';
        }
        // 刷新
        this.setState({ listNa: na, fromWhere: this.gameType, isMenu: false });
        // 默认获取第一页列表
        this.getGameList();
        // 展示横幅广告5
        if (window.showNor5Ad) {
            window.showNor5Ad();
        }
    }
    // 组件刷新
    componentDidUpdate() {
        // 获取游戏id
        const { gty } = this.props.match.params;
        // 判断id是否改变了
        if (Number(gty) !== this.gameType) {
            // 游戏列表
            this.gameList = [];
            // 重新请求数据
            this.requestFromServer();
        }
    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 判断是否存在
        if (!window.showInterAd) {
            // 引用AdJs
            initAdScript();
        }
        // 从服务器请求数据
        this.requestFromServer();
        // 展示插屏广告
        if (window.showInterAd) {
            window.showInterAd();
        }
    }
    /**
     * 获取游戏列表
     * @param {*} _cb : 回调
     */
    getGameList(_cb) {
        // 判断类型
        if (this.gameType === GlobalCtg.listType.last) { // 最近
            // 获取本地数据
            var list = getLastGameList();
            // 判断是否存在
            if (list && list.length > 0) {
                // 临时数据
                let tmp = {};
                tmp.ty = 1;
                // 判断是否第一页
                if (this.loadPage === 1) {
                    // 当前列表
                    this.gameList = [];
                }
                // 遍历
                for (let i = 0; i < list.length; i++) {
                    if (i % 2 === 0) {
                        tmp = {};
                        tmp.ty = 1;
                        tmp.left = list[i];
                        if (i === (list.length - 1)) {
                            // 添加到列表中
                            this.gameList.push(tmp);
                        }
                    }
                    if (i % 2 === 1) {
                        tmp.right = list[i];
                        // 添加到列表中
                        this.gameList.push(tmp);
                    }
                }
                // 判断是否第一页
                if (this.loadPage === 1 && this.gameList.length >= 5) {
                    // 插入广告占位
                    this.gameList.splice(5, 0, { ty: 2, left: 0, right: 0 });
                }
                // 刷新数据
                this.setState({ showList: this.gameList, isEmpty: this.gameList.length <= 0, hasMore: false }, () => {
                    // 判断是否第一页
                    if (this.loadPage === 1 && this.gameList.length > 5) {
                        // 展示横幅广告5
                        // if (window.showNor5Ad) {
                        //     window.showNor5Ad();
                        // }
                    }
                });
            } else {
                // 刷新数据
                this.setState({ isEmpty: true, hasMore: false });
            }
            // 回调
            if (_cb) {
                _cb();
            }
        } else {
            // 展示loading
            this.setState({ isLoading: true, isEmpty: false });
            // 请求数据
            doQueryList(this.gameType, this.loadPage, (_resultObj) => {
                // 判断是否挂载
                if (!this.mounted) {
                    // 回调
                    if (_cb) {
                        _cb();
                    }
                    return
                }
                // 关闭loading
                this.setState({ isLoading: false });
                // 判断状态
                if (_resultObj.s === 0) {
                    // 是否有数据
                    if (_resultObj.data && _resultObj.data.list && _resultObj.data.list.length > 0) {
                        // 新增数据列表
                        var addList = _resultObj.data.list;
                        // 临时数据
                        let tmp = {};
                        tmp.ty = 1;
                        // 判断是否第一页
                        if (this.loadPage === 1) {
                            // 当前列表
                            this.gameList = [];
                        }
                        // 遍历
                        for (let i = 0; i < addList.length; i++) {
                            if (i % 2 === 0) {
                                tmp = {};
                                tmp.ty = 1;
                                tmp.left = addList[i];
                                if (i === (addList.length - 1)) {
                                    // 添加到列表中
                                    this.gameList.push(tmp);
                                }
                            }
                            if (i % 2 === 1) {
                                tmp.right = addList[i];
                                // 添加到列表中
                                this.gameList.push(tmp);
                            }
                        }
                        // 判断是否第一页
                        if (this.loadPage === 1) {
                            // 插入广告占位
                            this.gameList.splice(5, 0, { ty: 2, left: 0, right: 0 });
                        }
                        // 刷新数据
                        this.setState({ showList: this.gameList, isEmpty: this.gameList.length <= 0, hasMore: true }, () => {
                            // 判断是否第一页
                            if (this.loadPage === 1) {
                                // 展示横幅广告5
                                // if (window.showNor5Ad) {
                                //     window.showNor5Ad();
                                // }
                            }
                            // 分页下标+1
                            this.loadPage++;
                        });
                    } else {
                        this.setState({ isEmpty: this.gameList.length <= 0, hasMore: false });
                    }
                } else {
                    Toast.show({ content: getErrorCodeMsg(_resultObj.s) });
                }
                // 回调
                if (_cb) {
                    _cb();
                }
            });
        }
    }
    /**
    * 获取更多数据
    * @param {*} _isTop ：是否顶部加载
    */
    loadMoreList(_isTop) {
        // 不是顶部
        if (!_isTop) {
            // 如果展示的数据列表为空，那么就不需要加载更多数据
            if (this.state.showList.length <= 0) {
                return
            }
        }
        // 判断是否加载中
        if (this.isLoadingData) {
            return;
        }
        // 标记加载中
        this.isLoadingData = true;
        // 判断是否顶部
        if (_isTop) {
            // 重置加载分页下标
            this.loadPage = 1;
        }
        // 加载列表
        this.getGameList(() => {
            // 标记不在加载中
            this.isLoadingData = false;
        });
    }
    /**
     * 检测是否还有更多需要加载
     */
    checkHasMore() {
        // 返回
        return this.state.hasMore;
    }
    /**
     * 关闭菜单
     */
    hideMenu() {
        // 隐藏菜单
        this.setState({ isMenu: false });
    }
    /**
     * 返回首页
     */
    gotoHome() {
        this.setState({ showWhat: 999 })
    }
    render() {
        // 获取数据
        const { isLoading, isEmpty, isMenu, fromWhere, showWhat, showList, listNa } = this.state;
        // 横幅广告组件5
        var adLayer = null;
        // 判断是否存在横幅5
        if (window.slot5Obj) {
            // 横幅组件5
            adLayer = <div id={window.slot5Obj.id} style={{ minWidth: window.slot5Obj.size[0] + 'px', minHeight: window.slot5Obj.size[1] + 'px', marginLeft: '0px' }}></div>
        } else if (window.ads5Obj) {
            // 横幅组件5
            adLayer = <Adsbygoogle adObj={window.ads5Obj} />
        }
        return (
            showWhat === 1 ?
                <div className="gamelist-page">
                    <div className='sticky'>
                        <div className='navBarLay' >
                            <div className='tmpLyr' >
                                <div className='titleLyr' >
                                    <div className='title' >{listNa}</div>
                                </div>
                                <div className='btnLyr' >
                                    <img className='img' src={IMAGE['btnMenu']} alt="" onClick={() => {
                                        // 展示菜单
                                        this.setState({ isMenu: true });
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {adLayer}
                    <div className='container'>
                        {
                            // 是否存在列表
                            showList.length <= 0 ?
                                <EmptyView visible={isEmpty} />
                                :
                                <PullToRefresh
                                    canReleaseText='pull-to-load'
                                    pullingText='pull-to-load'
                                    completeText='load completed'
                                    refreshingText='loading'
                                    onRefresh={async () => {
                                        // 重新加载列表
                                        this.loadMoreList(true);
                                    }}
                                >
                                    <List className='listLyr'>
                                        {showList.map((item, index) => (
                                            <List.Item key={index}>
                                                {
                                                    item.ty === 2 ?
                                                        <div className='gpt-adLyr'>
                                                            {/* {adLayer} */}
                                                        </div>
                                                        :
                                                        <div className='rowLyr'>
                                                            <GameIcon that={this} data={item.left} />
                                                            <GameIcon that={this} data={item.right} />
                                                        </div>
                                                }

                                            </List.Item>
                                        ))}
                                    </List>
                                    <InfiniteScroll className='infiniteScroll' loadMore={() => { this.loadMoreList() }} hasMore={this.checkHasMore()} >
                                        <InfiniteScrollContent hasMore={this.checkHasMore()} />
                                    </InfiniteScroll>
                                </PullToRefresh>
                        }
                    </div>
                    {
                        // 是否存在列表
                        showList.length <= 0 ? null : <PageBottom />
                    }
                    <LoadingView visible={isLoading} />
                    <MyMenu that={this} isVisible={isMenu} where={fromWhere} />
                </div>
                :
                <Redirect to={'/'} />
        )
    }
}

/**
 * 游戏盒子列表
 */
function InfiniteScrollContent(props) {
    // 是否还有更多
    let hasMore = props.hasMore;
    return < >
        {
            hasMore ? (
                <>
                    <span>Loading</span>
                    <DotLoading />
                </>
            ) : (
                <span>--- There's nothing more ---</span>
            )
        }
    </>
}
export default withRouter(IndexPage)