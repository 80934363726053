import React from 'react';
import { NavBar } from 'antd-mobile';
import { withRouter } from "react-router-dom";
import './styles.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="agreement-page">
                <div className='sticky'>
                    <NavBar
                        className='navBar'
                        onBack={() => {
                            window.history.back(-1);
                        }}
                        style={{
                            '--height': '50px',
                        }}
                    >Agreement</NavBar>
                </div>
                <iframe
                    id='privacyIframe'
                    className='privacyif'
                    src={'https://' + window.curDomain + '/User_agreement.html'}
                    title='privacy'
                    width="100%"
                    style={{ border: 'none' }}
                />
            </div>
        )
    }
}

export default withRouter(IndexPage)