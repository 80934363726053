import React from 'react';
import { NavBar, Mask } from 'antd-mobile';
import { withRouter } from "react-router-dom";
import { LoadingView } from '@component';
import { getTrueUrl, initAdScript } from '@util/GameUtil';

import './styles.less';

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, // 是否加载中
            gameNa: '', // 游戏名字
            gameVer: null, // 游戏版本
            isInterAd: false, // 是否展示插屏广告
        }
        // 组件是否挂载了
        this.mounted = false;
    }
    // 从服务器请求数据
    requestFromServer() {

    }
    // 组件卸载回调
    componentWillUnmount() {
        // 标记组件未挂载
        this.mounted = false;
    }
    // 组件加载回调
    componentDidMount() {
        // 标记组件挂载了
        this.mounted = true;
        // 判断是否存在
        if (!window.showInterAd) {
            // 引用AdJs
            initAdScript();
        }
        // 从服务器请求数据
        this.requestFromServer();
        // 刷新界面
        this.updateView();
        // 展示banner
        if (window.showBannerAd) {
            window.showBannerAd();
        }
        // 展示插屏广告
        if (window.showInterAd) {
            window.showInterAd();
        }
    }
    // 刷新界面
    updateView() {
        // 获取游戏数据
        const { zn } = this.props.match.params;
        // 是否存在数据
        if (zn && zn.length > 0) {
            // 解析数据
            const data = zn.split('__aa__');
            // 校验数据
            if (data && data.length === 2) {
                // 刷新数据
                this.setState({ gameNa: data[1], gameVer: data[0] });
            }
        }
    }
    render() {
        // 获取数据
        const { isLoading, isInterAd, gameNa, gameVer } = this.state;
        // 广告组件
        var adLayer = null;
        // 判断是否存在banner
        if (window.bnrObj) {
            // banner组件
            adLayer = <div id={window.bnrObj.id} style={{ minWidth: window.bnrObj.size[0] + 'px', minHeight: window.bnrObj.size[1] + 'px', marginLeft: '0px' }}></div>
        }
        return (
            <div className="gameplay-page">
                <div className='sticky'>
                    <NavBar
                        className='navBar'
                        onBack={() => {
                            window.history.back(-1);
                        }}
                        style={{
                            '--height': '50px',
                        }}
                    >{gameNa ? gameNa : ''}</NavBar>
                </div>
                <LoadingView visible={isLoading} />
                {
                    gameVer ?
                        <iframe
                            id='gameIframe'
                            className='gameif'
                            src={getTrueUrl(3, gameVer)}
                            title={gameNa ? gameNa : ''}
                            width="100%"
                            style={{ border: 'none' }}
                        />
                        :
                        null
                }
                {adLayer}
                {
                    isInterAd ?
                        <Mask visible={isInterAd} onMaskClick={() => {
                            // this.setState({ isInterAd: false })
                        }}>
                            <div className='overlayContent'>
                                {/* <div id="banner-ad1" style={{ width: '750px', height: '200px' }}></div> */}
                            </div>
                        </Mask>
                        :
                        null
                }
            </div>
        )
    }
}

export default withRouter(IndexPage)