import localStorage from "localStorage";
import { encodeToSever, decodeForClient } from '@util/HttpUtil';

// 是否手机设备
export function isMobile() {
    return document.querySelector('body').offsetWidth <= 1080;
}
// 获取本地数据
export function getLocalData(key) {
    let data = localStorage.getItem(key);
    if (data && data !== 'null') {
        data = decodeForClient(data);
        data = JSON.parse(data);
    }
    return data;
}
// 保存本地数据
export function setLocalData(key, value) {
    if (value) {
        value = JSON.stringify(value);
        value = encodeToSever(value);
    }
    localStorage.setItem(key, value)
}
// 删除本地数据
export function removeLocalData(key) {
    localStorage.removeItem(key)
}
/**
 * 随机数
 */
export function randomValue(_min, _max) {
    return Math.floor(Math.random() * (_max - _min + 1) + _min);
}
/**
 * 获取当前时间
 * @param {boolean} _bSecond 单位是否为秒数，默认是毫秒
 */
export function getCurrentTime(_bSecond) {
    // 当前时间
    var tmpTime = Date.now();
    // 判断是否为秒数
    if (_bSecond) {
        tmpTime = Math.floor(tmpTime / 1000);
    }
    // 返回
    return tmpTime;
}

/**
 * 获取错误id提示
 * @param {*} _id ：错误id
 */
export function getErrorCodeMsg(_id) {
    // 提示
    var tips = '请求失败';
    // 判断id
    if (_id === 1000) {
        tips = '';
    }
    // 返回
    return tips;
}
/**
 * 判断手机号码是否合法
 * @param {*} _phoneNumber ：手机号码
 */
export function isValidPhoneNumber(_phoneNumber) {
    // var pattern = /^1[3456789]\d{9}$/;
    // return pattern.test(_phoneNumber);
    let pattern = /^1[3-9]{1}[0-9]{9}$/;
    return pattern.test(_phoneNumber)
}
/**
 * 判断密码是否合法
 * @param {*} _password ：密码
 */
export function isValidPassword(_password) {
    let pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/;
    return pattern.test(_password)
}
/**
 * 检查身份证号码
 * @param {*} _idcard ：身份证号码 
 */
export function checkIdcard(_idcard) {
    const reg = /^[1-9]\d{5}((19|20)\d{2})(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])\d{3}([0-9]|X)$/;
    if (!reg.test(_idcard)) {
        return false; // 格式不正确，直接返回false
    }
    return true
    // // 格式正确，再校验身份证号码的有效性
    // const coeffs = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权系数
    // const calCodeMap = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]; // 校验码对应规则
    // let res = 0;
    // for (let i = 0; i < 17; i++) {
    //     res += parseInt(_idcard[i]) * coeffs[i];
    // }
    // const calCode = calCodeMap[res % 11];
    // const lastCode = _idcard[17];
    // console.log('tttst=>',(calCode === lastCode))
    // return calCode === lastCode;
}
/**
 * 隐藏手机号码中间4位
 * @param {*} _phoneNumber ：密码
 */
export function hiddenPhoneNumber(_phoneNumber) {
    var phoneNumber = _phoneNumber + '';
    // 返回
    return phoneNumber.substring(0, 3) + '****' + phoneNumber.substring(7);
}
/**
 * 保存最热列表到本地
 * @param {*} _list ：列表
 */
export function saveHotListToLocal(_list) {
    // 保存数据
    setLocalData('hfhotlist', _list);
}
/**
 * 获取展示在游戏详情里的热门推荐列表
 * @param {*} _curGid ：当前id
 */
export function getHotListForDetails(_curGid) {
    // 随机排序
    var newList = [];
    // 获取随机任务列表
    randomSort(getLocalData('hfhotlist'), newList);
    // 展示的列表
    var list = [];
    // 遍历
    for (let i = 0; i < newList.length; i++) {
        // 判断是否当前游戏
        if (newList[i].gid !== Number(_curGid)) {
            // 添加到列表
            list.push(newList[i]);
        }
        // 判断数量
        if (list.length === 8) {
            break;
        }
    }
    // 返回
    return list;
}
/**
 * 保存游戏到本地
 * @param {*} _obj ：游戏数据 
 */
export function saveLastGameToLocal(_obj) {
    // 构建数据体
    var gObj = {};
    // id
    gObj.gid = _obj.gid;
    // icon
    gObj.ic1 = _obj.ic1;
    // 名字
    gObj.na = _obj.na;
    // 星数
    gObj.sn = _obj.sn;
    // 获取最近列表
    let list = getLocalData('hflastlist');
    // 判断是否存在
    if (list && list.length > 0) {
        // 遍历
        for (let i = list.length - 1; i >= 0; i--) {
            // 判断是否存在
            if (list[i].gid === gObj.gid) {
                list.splice(i, 1);
                break;
            }
        }
    } else {
        // 初始化
        list = [];
    }
    // 添加到表头
    list.unshift(gObj);
    // 保存到本地
    setLocalData('hflastlist', list);
}
/**
 * 获取最近游戏列表
 */
export function getLastGameList() {
    // 获取最近列表
    return getLocalData('hflastlist');
}
/**
 * 数组随机排序
 * @param {*} arr ：原本的数组
 * @param {*} newArr ：新的数组
 */
export function randomSort(arr, newArr) {
    // 如果原数组arr的length值等于1时，原数组只有一个值，其键值为0
    // 同时将这个值push到新数组newArr中
    if (arr.length === 1) {
        newArr.push(arr[0]);
        return newArr; // 相当于递归退出
    }
    // 在原数组length基础上取出一个随机数
    var random = Math.ceil(Math.random() * arr.length) - 1;
    // 将原数组中的随机一个值push到新数组newArr中
    newArr.push(arr[random]);
    // 对应删除原数组arr的对应数组项
    arr.splice(random, 1);
    return randomSort(arr, newArr);
}
/**
 * 克隆数据
 * @param {*} obj : 数据
 */
export function cloneObj(obj) {
    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) {
        return obj;
    }
    // Handle Date
    if (obj instanceof Date) {
        var copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    // Handle Array
    if (obj instanceof Array) {
        let copy = [];
        let len = obj.length;
        for (let i = 0; i < len; ++i) {
            copy[i] = cloneObj(obj[i]);
        }
        return copy;
    }
    // Handle Object
    if (obj instanceof Object) {
        let copy = {};
        for (let attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = cloneObj(obj[attr]);
        }
        return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
}
/**
 * 获取url
 * @param {*} _ty ：类型 1：banner 2:icon 3：h5游戏
 * @param {*} _val ：替换的值
 */
export function getTrueUrl(_ty, _val) {
    // url
    let url = 'https://battle.xiaoyouxi666.com:2201/';
    // 判断当前环境
    if (process.env.REACT_APP_MODE === 'production') { // 生产环境
        url = 'https://cdnca.cash-wall.com/'
    }
    // 判断类型
    if (_ty === 1) { // banner 
        url += 'hfbox/banner/xxx.jpg';
    } else if (_ty === 2) { // icon 
        url += 'hfbox/area/xxx.jpg';
    } else {
        url += 'hfbox/game/xxx/index.html';
    }
    // 替换字符串
    url = url.replace('xxx', _val);
    // 返回
    return url;
}
/**
 * 引用AdJs
 */
export function initAdScript() {
    console.log('reload--initScript')
    // 使用window.location对象获取当前页面的URL
    var url = window.location.href;
    // 使用正则表达式解析出域名
    window.curDomain = url.match(/:\/\/(.[^/]+)/)[1];
    // 脚本控件
    var scriptCom = document.createElement("script");
    // 指向文件
    scriptCom.src = "https://" + window.curDomain + "/AdJs.js";
    // 跨域控制
    scriptCom.crossOrigin = "anonymous";
    // 同步加载
    scriptCom.async = false;
    // 添加到文件头
    document.head.appendChild(scriptCom);
}
