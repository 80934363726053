import React from 'react';

class Advertisement extends React.Component {
    componentDidMount() {
        try {
            // 确保adsbygoogle库已加载
            window.adsbygoogle = window.adsbygoogle || [];
            window.adsbygoogle.push({});
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const { adObj } = this.props;
        // 广告
        var adLyr = null;
        // 判断是否存在
        if (adObj) {
            // 判断类型
            if (adObj.adTy === 1) {
                adLyr = <ins
                    className="adsbygoogle"
                    style={{ display: 'inline-block', width: adObj.size[0] + 'px', height: adObj.size[1] + 'px' }}
                    // data-ad-layout="in-article"
                    // data-ad-format="fluid"
                    data-ad-client={adObj.client}
                    data-ad-slot={adObj.slot}
                />
            } else if (adObj.adTy === 2) {
                adLyr = <ins
                    className="adsbygoogle"
                    style={{ display: 'block', width: adObj.size[0] + 'px', height: adObj.size[1] + 'px' }}
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                    data-ad-client={adObj.client}
                    data-ad-slot={adObj.slot}
                />
            }
        }
        return (
            <div>
                {adLyr}
            </div>
        );
    }
}

export default Advertisement;