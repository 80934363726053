import React from "react";
import { useHistory } from 'react-router-dom';
import { IMAGE } from "@/assets";
import "./styles.less";

const IndexComponent = (props) => {
    const history = useHistory();
    return (
        <div className="page-bottom">
            <div className="bottomLogoLyr">
                <img className='bottomLogo' src={IMAGE['hfboxLogo']} alt="" />
            </div>
            <div className="agreeLry">
                <div className='agreeTxt'
                    onClick={() => {
                        // 展示协议
                        let path = {
                            pathname: '/home/agreement/',
                        }
                        // 跳转
                        history.push(path);
                    }}>User Agreement</div>
                <div className='agreeTxt'>|</div>
                <div className='agreeTxt'
                    onClick={() => {
                        // 展示协议
                        let path = {
                            pathname: '/home/privacy/',
                        }
                        // 跳转
                        history.push(path);
                    }}>Privacy Policy</div>
            </div>
            <div className="rightTxt">
                @2023-2024 All Rights Reserved
            </div>
        </div>

    )
}
export default IndexComponent;
