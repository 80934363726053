import React from "react";
import { Popup, Toast } from 'antd-mobile';
import { IMAGE } from "@/assets";
import { GlobalCtg } from "@config/config";
import { getLastGameList } from '@util/GameUtil';

import "./styles.less";

const IndexComponent = (props) => {
    const { that, isVisible, where } = props;
    /**
     * 展示列表
     * @param {*} _ty : 类型 -1：首页 100是全部，101是最热，102是最新，103是推荐
     */
    const showGameList = function (_ty) {
        // 判断是否当前
        if (where === _ty) {
            // 关闭菜单
            that.hideMenu();
        } else {
            // 判断是否首页
            if (_ty === -1) { // 首页
                // 返回好首页
                that.gotoHome()
            } else {
                // 判断是否最后玩的列表
                if (_ty === GlobalCtg.listType.last) {
                    // 获取最近列表
                    var locallist = getLastGameList();
                    // 判断是否存在
                    if (!locallist || locallist.length <= 0) { // 不存在
                        // 提示
                        Toast.show({ content: "There's no data here, let's go play games" });
                        return
                    }
                }
                // 展示游戏列表
                let path = {
                    pathname: '/home/gamelist/' + _ty,
                }
                // 跳转
                that.props.history.push(path);
            }
        }
    }
    return (
        <Popup
            visible={isVisible}
            onMaskClick={() => {
                // 关闭菜单
                that.hideMenu();
            }}
            onClose={() => {
                // 关闭菜单
                that.hideMenu();
            }}
            showCloseButton
            position='left'
            bodyStyle={{ width: '55vw' }}
            style={{ '--z-index': 9999999999 }}
        >
            <div className="myMenuLyr">
                <div className="menuItem" onClick={() => { showGameList(-1); }}>
                    <img className='icon1' src={IMAGE['btnHome']} alt="" />
                    <div className="txt">Home</div>
                </div>
                <div className="menuItem" onClick={() => { showGameList(GlobalCtg.listType.push); }}>
                    <img className='icon6' src={IMAGE['btnRec']} alt="" />
                    <div className="txt">Recommend</div>
                </div>
                <div className="menuItem" onClick={() => { showGameList(GlobalCtg.listType.news); }}>
                    <img className='icon2' src={IMAGE['btnNew']} alt="" />
                    <div className="txt">New games</div>
                </div>
                <div className="menuItem" onClick={() => { showGameList(GlobalCtg.listType.hots); }}>
                    <img className='icon3' src={IMAGE['btnHot']} alt="" />
                    <div className="txt">Hot games</div>
                </div>
                <div className="menuItem" onClick={() => { showGameList(GlobalCtg.listType.last); }}>
                    <img className='icon4' src={IMAGE['btnLast']} alt="" />
                    <div className="txt">Played games</div>
                </div>
                <div className="menuItem" onClick={() => { showGameList(GlobalCtg.listType.all); }}>
                    <img className='icon5' src={IMAGE['btnAll']} alt="" />
                    <div className="txt">All Games</div>
                </div>
            </div>
        </Popup>
    )
}

export default IndexComponent;
