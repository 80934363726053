import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
    Home, GameList, Details, Gameplay, Privacy, Agreement
} from "@/page";
import { Main } from '@component';
import { ProvideAuth } from '@component/auth/provideAuth';
import "./App.less";

function App() {
    return (
        <ProvideAuth>
            <Router basename="">
                <Switch>
                    <Main>
                        <Route exact path="/"><Home /></Route>
                        <Route path="/home/gamelist/:gty"><GameList /></Route>
                        <Route path="/home/details/:gid"><Details /></Route>
                        <Route path="/home/gameplay/:zn"><Gameplay /></Route>
                        <Route path="/home/privacy"><Privacy /></Route>
                        <Route path="/home/agreement"><Agreement /></Route>
                        {/* <PrivateRoute >
                            <Redirect to='/' />
                        </PrivateRoute> */}
                    </Main>
                </Switch>
            </Router>
        </ProvideAuth>
    )
}
export default App;