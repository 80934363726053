import tabbarHome from "./tabbar/home.png"
import tabbarHomeSel from "./tabbar/home-sel.png"
import tabbarCash from "./tabbar/cash.png"
import tabbarCashSel from "./tabbar/cash-sel.png"
import tabbarData from "./tabbar/data.png"
import tabbarDataSel from "./tabbar/data-sel.png"
import tabbarUser from "./tabbar/user.png"
import tabbarUserSel from "./tabbar/user-sel.png"
import tabbarDytg from "./tabbar/dytg.png"
import tabbarDytgSel from "./tabbar/dytg-sel.png"
import hfboxLogo from "./hfbox-logo.png"
import playIcon from "./play-icon.png"
import btnAll from "./btn-all.png"
import btnRec from "./btn-rec.png"
import btnHome from "./btn-home.png"
import btnHot from "./btn-hot.png"
import btnLast from "./btn-last.png"
import btnMenu from "./btn-menu.png"
import btnNew from "./btn-new.png"

// const BASEURL = "https://myj-nft-image.obs.cn-east-3.myhuaweicloud.com/h5"
// const tabbarExplore = `${BASEURL}/tabbar/explore.png`
// const tabbarExploreSel = `${BASEURL}/tabbar/explore-sel.png`

const IMAGE = {
    tabbarHome, tabbarHomeSel, tabbarCash, tabbarCashSel, tabbarData, tabbarDataSel, tabbarUser, tabbarUserSel, tabbarDytg, tabbarDytgSel,
    hfboxLogo, playIcon, btnAll, btnHome, btnRec, btnHot, btnLast, btnMenu, btnNew
}

export default IMAGE